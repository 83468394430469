<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Venue
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="venue2-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Color"
            v-model="fields.color"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('color')"
            :error-messages="errors['color']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="venue2-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      venue: {},
      fields: {
        color: null,
        name: null,
      },
      errors: {},
    };
  },

  methods: {
    open(venue = null) {
      if (venue !== null) {
        this.venue = venue;
        this.isEditing = true;
        this.fields.name = venue.name;
        this.fields.color = venue.color;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.venueId = this.venue.id;
      }

      this.$store
        .dispatch("craigtoun/venuesStore/saveVenue", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.event = {};
      this.fields = {
        name: null,
        color: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
