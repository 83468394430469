<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Venues</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  class="mr-2"
                  :to="{ name: 'module-craigtoun-venues' }"
                >
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="$refs.venueDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Venue</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="venues"
        no-data-text="No Venues Found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-craigtoun-venues-individual',
                  params: { venueId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.venueDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="deleteVenue.dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Archive Venue</v-card-title>
          <v-card-text
            >Are you sure you wish to archive
            {{ deleteVenue.venue.name }}?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteVenue.loading"
              @click="saveDelete"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <venue-dialog ref="venueDialog" />
  </div>
</template>

<script>
import VenueDialog from "./components/VenueDialog";

export default {
  components: {
    VenueDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Venue Hires",
          disabled: false,
          to: {
            name: "module-craigtoun-venues",
          },
          exact: true,
        },
        {
          text: "Venues",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteVenue: {
        dialog: false,
        venue: {},
        loading: false,
      },
    };
  },

  computed: {
    venues() {
      return this.$store.getters["craigtoun/venuesStore/all"];
    },
  },

  methods: {
    openDelete(venue) {
      this.deleteVenue.venue = venue;
      this.deleteVenue.dialog = true;
    },

    resetDelete() {
      this.deleteVenue.dialog = false;
      this.deleteVenue.venue = {};
      this.deleteVenue.loading = false;
    },

    saveDelete() {
      this.deleteVenue.loading = true;

      this.$store
        .dispatch("craigtoun/venuesStore/deleteVenue", {
          appId: this.$route.params.id,
          venueId: this.deleteVenue.venue.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteVenue.loading = false;
        });
    },
  },
};
</script>
